import {FC, useEffect, useState} from 'react';

import './styles.scss';
import env from '~/config/appEnv';

import {TabData} from './types';

const FakeTabs: FC = () => {
  const [data, setData] = useState<TabData[]>([]);

  useEffect(() => {
    if (!env.REACT_APP_PROMO_URL || process.env.NODE_ENV !== 'production') {
      return;
    }

    fetch(env.REACT_APP_PROMO_URL, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="ptabs">
      {data.map((item) => (
        <a
          href={item.url}
          key={item.url}
          target="_blank"
          rel="noreferrer noopener"
          title={item.title}
        >
          {item.title}
        </a>
      ))}
    </div>
  );
};

export default FakeTabs;
