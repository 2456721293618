import {useEffect, Suspense} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {useStore, useStoreMap} from 'effector-react';

import {Category} from '~/store/types';
import {Banner, FakeTabs} from '~/components/common/Promo';
import Breadcrumbs from '~/components/common/Breadcrumbs';
import {$profileTypes} from '~/store/profiles';
import {$city} from '~/store/cities';

import NavigationCarousel from './components/NavigationCarousel';
import {loadBreadcrumbsFx, $breadcrumbs} from './store';

const LocationPage = () => {
  const params = useParams();
  const breadcrumbs = useStore($breadcrumbs);
  const city = useStore($city);
  const categoryUrl = params['*'] || null;

  const currentCategory = useStoreMap({
    store: $profileTypes,
    keys: [categoryUrl],
    fn: (types, [url]) => {
      const categories = types.filter(Boolean) as Category[];

      return categories.find((type) => type.url === url) || null;
    },
  });

  useEffect(() => {
    if (city) {
      loadBreadcrumbsFx({
        city,
        category: currentCategory,
      });
    }
  }, [city, currentCategory]);

  return (
    <div className="content" data-testid="escort-list">
      <FakeTabs />
      <Banner area="header" width="300" height="100" />
      <NavigationCarousel className="bg-white" />
      <Breadcrumbs links={breadcrumbs} />
      <Suspense fallback={<div style={{minHeight: '80vh'}} />}>
        <Outlet />
      </Suspense>
      <Banner area="footer" width="320" height="250" />
    </div>
  );
};

export default LocationPage;
