import type {EventArgs, TimingArgs, FieldsObject} from 'react-ga';

import {router} from '~/router';
import env from '~/config/appEnv';

let _ReactGA: any = null;

export const gaInit = async () => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  try {
    const {default: ReactGA} = await import('react-ga');

    _ReactGA = ReactGA;

    if (env.REACT_APP_GOOGLE_ANALYTICS_ID && router) {
      const {pathname, search} = router.state.location;

      ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        debug: false,
      });
      ReactGA.pageview(pathname + search);
    }

    router?.subscribe(({location}) => {
      if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.pageview(location.pathname + location.search);
      }
    });
  } catch (e) {
    console.error('Cannot initialize ReactGA');
  }
};

export const gaLogEvent = (data: EventArgs): void => {
  if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    _ReactGA?.event(data);
  }
};

export const gaLogTiming = (data: TimingArgs): void => {
  if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    _ReactGA?.timing(data);
  }
};

export const gaSet = (data: FieldsObject): void => {
  if (env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    _ReactGA?.set(data);
  }
};

export * from '~/config/googleAnalytics';
